import landscape from "./BG3.jpg";
import { AddressSearch } from "./AddressSearch";
import { AgentPage } from "../Agents/AgentPage";
import { CommissionCalc } from "../CommissionCalc/CommissionCalc";
import { InstagramEmbeds } from "../InstagramEmbeds/InstagramEmbeds";
import { Included } from "../Included/Included";
import { useRecoilValue } from "recoil";
import { windowState } from "../recoil/windowState";
import { Listings } from "../Listings/Listings";

export const Home = () => {
  const { height } = useRecoilValue(windowState);
  return (
    <div style={{ width: "100vw" }}>
      <div
        style={{
          fontSize: "2.5rem",
          // backgroundImage: `url(${landscape})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          height:
            height * 0.13 < 80 ? `${height - 80}px` : `${height * 0.87}px`,
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100vw",
            }}
          >
            {/* <AddressSearch /> */}
          </div>
        </div>
      </div>
      {/* <Listings page="home" /> */}
      {/* <AgentPage /> */}

      {/* <CommissionCalc /> */}
      {/* <Included /> */}
    </div>
  );
};
