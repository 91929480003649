import {
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  MenuOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  FloatButton,
  Form,
  Image,
  Input,
  MenuProps,
  Modal,
  message,
} from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useAuth } from "../AuthProvider/AuthProvider";
import { windowState } from "../recoil/windowState";
import logo from "./White H.png";
import { analytics } from "../linker/linker";
import { logEvent } from "firebase/analytics";

export const Header = () => {
  const goTo = useNavigate();
  const { width, height } = useRecoilValue(windowState);
  const [isLogin, setIsLogin] = useState(false);
  const [loginForm] = Form.useForm();
  const authContext = useAuth();

  const handleLogin = () => {
    loginForm
      .validateFields()
      .then((values) => {
        authContext.login(values.email, values.password, "/dashboard");
        setIsLogin(false);
      })
      .catch((err) => {
        message.error("Sorry, did you have a login?");
        console.error(err);
      });
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <Button type="text" onClick={() => goTo("/")}>
          HOME
        </Button>
      ),
    },
    // {
    //   key: "2",
    //   label: (
    //     <Button type="text" onClick={() => goTo("/buy")}>
    //       BUY
    //     </Button>
    //   ),
    // },
    {
      key: "3",
      label: (
        <Button
          type="text"
          onClick={() => {
            logEvent(analytics, "select_item", {
              item_list_name: "Jesse Herschell page",
            });
            goTo("/the-herschell-team");
          }}
        >
          JESSE
        </Button>
      ),
    },
    {
      key: "4",
      label: (
        <Button
          type="text"
          onClick={() => {
            logEvent(analytics, "select_item", {
              item_list_name: "Dashboard page",
            });
            goTo("/dashboard");
          }}
        >
          DASHBOARD
        </Button>
      ),
    },
    {
      key: "5",
      label: (
        <Button
          type="text"
          onClick={() =>
            authContext.currentUser ? authContext.logout() : setIsLogin(true)
          }
        >
          {authContext.currentUser ? "LOGOUT" : "LOGIN"}
        </Button>
      ),
    },

    {
      key: "6",
      label: (
        <Button
          type="text"
          onClick={() => {
            logEvent(analytics, "select_item", {
              item_list_name: "Contact page",
            });
            goTo("/contact");
          }}
          style={{ fontWeight: "bold" }}
        >
          CONTACT US
        </Button>
      ),
    },
    {
      key: "7",
      label: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
            fontFamily: "Lato",
          }}
        >
          <Button
            type="text"
            icon={
              <FacebookOutlined
                style={{ fontSize: width < 1400 ? "20px" : "1.5vw" }}
              />
            }
            onClick={() => {
              logEvent(analytics, "select_item", {
                item_list_name: "Click through to Facebook",
              });
              window.open("https://www.facebook.com/herschell.nz");
            }}
          />
          <Button
            size="large"
            type="text"
            icon={
              <InstagramOutlined
                style={{ fontSize: width < 1400 ? "20px" : "1.5vw" }}
              />
            }
            onClick={() => {
              logEvent(analytics, "select_item", {
                item_list_name: "Click through to Instagram",
              });
              window.open("https://www.instagram.com/jesseherschell/");
            }}
          />
          <Button
            size="large"
            type="text"
            icon={
              <YoutubeOutlined
                style={{ fontSize: width < 1400 ? "20px" : "1.5vw" }}
              />
            }
            onClick={() => {
              logEvent(analytics, "select_item", {
                item_list_name: "Click through to YouTube",
              });
              window.open("https://www.facebook.com/herschell.nz");
            }}
          />
          <Button
            type="text"
            icon={
              <LinkedinOutlined
                style={{ fontSize: width < 1400 ? "20px" : "1.5vw" }}
              />
            }
            onClick={() => {
              logEvent(analytics, "select_item", {
                item_list_name: "Click through to LinkedIn",
              });
              window.open("https://www.facebook.com/jesse.herschell.9");
            }}
          />
        </div>
      ),
    },
  ];
  return (
    <div
      style={{
        width: "100vw",
        height: `${height * 0.13}px`,
        minHeight: "80px",
        background: "rgb(15,30,66)",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        color: "white",
        boxShadow: "3px 3px 10px 5px rgb(15,30,66)",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
          alignItems: "center",
        }}
      >
        <Image
          preview={false}
          //height={"16vh"}
          src={logo}
          style={{
            minHeight: `${50}px`,
            height: `${height * 0.1}px`,
            cursor: "pointer",
          }}
          onClick={() => goTo("/")}
        />
      </div>
      <div
        style={{
          paddingRight: "3vw",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        {width > 1000 && (
          <div
            style={{
              paddingLeft: "3vw",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              fontWeight: "bold",
            }}
          >
            <Button
              type="text"
              onClick={() => {
                logEvent(analytics, "select_item", {
                  item_list_name: "Home page",
                });
                goTo("/");
              }}
              style={{ fontFamily: "Lato", color: "whitesmoke" }}
            >
              HOME
            </Button>
            {/* <Button
              type="text"
              onClick={() => goTo("/buy")}
              style={{ fontFamily: "Lato", color: "whitesmoke" }}
            >
              BUY
            </Button> */}
            <Button
              type="text"
              onClick={() => {
                logEvent(analytics, "select_item", {
                  item_list_name: "Jesse Herschell page",
                });
                goTo("/the-herschell-team");
              }}
              style={{ fontFamily: "Lato", color: "whitesmoke" }}
            >
              JESSE
            </Button>
            {authContext.currentUser ? (
              <>
                <Button
                  type="text"
                  onClick={() => {
                    logEvent(analytics, "select_item", {
                      item_list_name: "Dashboard page",
                    });
                    goTo("/dashboard");
                  }}
                  style={{ fontFamily: "Lato", color: "whitesmoke" }}
                >
                  DASHBOARD
                </Button>
                <Button
                  type="text"
                  onClick={() => authContext.logout()}
                  style={{ fontFamily: "Lato", color: "whitesmoke" }}
                >
                  LOGOUT
                </Button>
              </>
            ) : (
              <Button
                type="text"
                onClick={() => {
                  setIsLogin(true);
                }}
                style={{ fontFamily: "Lato", color: "whitesmoke" }}
              >
                LOGIN
              </Button>
            )}
          </div>
        )}
        {width > 600 && (
          <>
            <FloatButton
              style={{ bottom: "200px" }}
              icon={
                <FacebookOutlined
                  style={{
                    color: "black",
                    backgroundImage: "rgba(0,0,0,0)",
                  }}
                />
              }
              onClick={() => {
                logEvent(analytics, "select_item", {
                  item_list_name: "Click through to Facebook",
                });
                window.open("https://www.facebook.com/herschell.nz");
              }}
            />
            <FloatButton
              style={{ bottom: "150px" }}
              icon={
                <InstagramOutlined
                  style={{
                    color: "black",
                    backgroundImage: "rgba(0,0,0,0)",
                  }}
                />
              }
              onClick={() => {
                logEvent(analytics, "select_item", {
                  item_list_name: "Click through to Instagram",
                });
                window.open("https://www.instagram.com/jesseherschell/");
              }}
            />
            <FloatButton
              style={{ bottom: "100px" }}
              icon={
                <YoutubeOutlined
                  style={{
                    color: "black",
                    backgroundImage: "rgba(0,0,0,0)",
                  }}
                />
              }
              onClick={() => {
                logEvent(analytics, "select_item", {
                  item_list_name: "Youtube click through to YouTube",
                });
                window.open("https://www.youtube.com/@Herschell_RealEstate");
              }}
            />
            <FloatButton
              style={{ bottom: "50px" }}
              icon={
                <LinkedinOutlined
                  style={{
                    color: "black",
                    backgroundImage: "rgba(0,0,0,0)",
                  }}
                />
              }
              onClick={() => {
                logEvent(analytics, "select_item", {
                  item_list_name: "Click through to LinkedIn",
                });
                window.open("https://www.facebook.com/herschell.nz");
              }}
            />
            {width >= 1000 && (
              <Button
                type="primary"
                style={{
                  fontWeight: "bold",
                  marginLeft: "1rem",
                  fontFamily: "Lato",
                }}
                onClick={() => {
                  logEvent(analytics, "select_item", {
                    item_list_name: "Contact page",
                  });
                  goTo("/contact");
                }}
              >
                CONTACT
              </Button>
            )}
          </>
        )}
        {width <= 1000 && (
          <div style={{ paddingRight: "2rem", paddingLeft: "2rem" }}>
            <Dropdown menu={{ items }}>
              <Button
                type="text"
                icon={
                  <MenuOutlined
                    style={{
                      fontSize: width < 1400 ? "20px" : "1.5vw",
                      color: "white",
                    }}
                  />
                }
              />
            </Dropdown>
          </div>
        )}
      </div>
      <Modal
        open={isLogin}
        onCancel={() => setIsLogin(false)}
        onOk={() => {
          handleLogin();
        }}
        okText="LOGIN"
        title="TEAM LOGIN"
      >
        <Form form={loginForm}>
          <Form.Item name="email">
            <Input placeholder="email" />
          </Form.Item>
          <Form.Item name="password">
            <Input.Password placeholder="Password" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
