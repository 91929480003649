import { message } from "antd";
import "firebase/auth";
import {
  IdTokenResult,
  ParsedToken,
  browserLocalPersistence,
  sendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";

import { analytics, auth, firestore } from "../linker/linker";
import { AuthContextType, HouseType } from "../typeDefs/typeDefs";
import { logEvent } from "firebase/analytics";
import {
  DocumentData,
  collection,
  onSnapshot,
  orderBy,
  query,
  startAfter,
} from "firebase/firestore";
import { useRecoilState } from "recoil";
import { housesState } from "../recoil/housesState";

const AuthContext = React.createContext<AuthContextType>(null!);
export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }: { children: any }) => {
  const goTo = useNavigate();

  const [currentUser, setCurrentUser] = useState<any>(null);
  const [currentRole, setCurrentRole] = useState<ParsedToken | null>(null);
  // const [houses, setHouses] = useRecoilState(housesState);
  //const unsub = useRef<DocumentData>();

  // const thisMonth = new Date().getMonth();
  // let filterMonth = 0;
  // let month = thisMonth - 3;

  // if (month < 0) {
  //   filterMonth = 12 + month;
  // } else if (month === 0) {
  //   filterMonth = 0;
  // } else {
  //   filterMonth = month;
  // }

  // const filterDate = new Date().setMonth(filterMonth);

  // useEffect(() => {
  //   let unsub;

  //   const q = query(
  //     collection(firestore, "houses"),
  //     orderBy("listDate", "desc"),
  //     startAfter(new Date(filterDate).getTime())
  //   ); //and(where("isDeleted", "!=", true))

  //   if (currentUser) {
  //     unsub = onSnapshot(q, (snapshot) => {
  //       //collection(firestore, "houses")
  //       setHouses([]);
  //       snapshot.forEach((snapDoc) => {
  //         setHouses((prev) => [
  //           ...prev,
  //           { ...snapDoc.data(), id: snapDoc.id } as HouseType,
  //         ]);
  //       });
  //     });
  //   } else {
  //     return unsub;
  //   }
  // }, [filterDate, currentUser]);
  const login = (email: string, password: string, path: string) => {
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        return signInWithEmailAndPassword(auth, email, password)
          .then(() => {
            logEvent(analytics, "login", {
              method: "Email and password successful",
            });
            goTo(path);
            return true;
          })
          .catch((err) => {
            logEvent(analytics, "login", {
              method: "Email and password failed",
            });
            message.error("Failed to login... Do you have an account?");
            return false;
          });
      })
      .catch((err) => console.error(err));
  };

  const logout = () => {
    return auth
      .signOut()
      .then(() => {
        //goTo("/");
        logEvent(analytics, "login", {
          method: "Logged out",
        });
        setCurrentUser(null);
        setCurrentRole(null);
      })
      .catch((err: any) => console.error(err));
  };

  const sendPasswordReset = async (email: any) => {
    return await sendPasswordResetEmail(auth, email);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user: any) => {
      setCurrentUser(user);
      if (!user) return;
      user
        .getIdTokenResult()
        .then((idTokenResult: IdTokenResult) => {
          // Confirm the user is an Admin.
          if (!!idTokenResult.claims) {
            // Show admin UI.
            setCurrentRole({ claims: idTokenResult.claims });
          } else {
            // Show regular user UI.
          }
        })
        .catch((error: any) => {
          console.log(error);
        });
    });
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    currentRole,
    login,
    logout,
    sendPasswordReset,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
