import { atom } from "recoil";

export const windowState = atom({
  key: "windowState",
  default: {
    width: window.screen.width,
    height: window.screen.height,
  },
  effects: [
    ({ setSelf }) => {
      const listener = () => {
        setSelf({
          width: window.screen.width,
          height: window.screen.height,
        });
      };
      window.addEventListener("resize", listener);
      return () => {
        window.removeEventListener("resize", listener);
      };
    },
  ],
});
