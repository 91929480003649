import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCZYzWsCcY-oxVCFvNF8k1xg5ys4uamoyk",
  authDomain: "herschellrealestate.firebaseapp.com",
  projectId: "herschellrealestate",
  storageBucket: "herschellrealestate.appspot.com",
  messagingSenderId: "489190497882",
  appId: "1:489190497882:web:342baa519479a9d0fac263",
  measurementId: "G-LGXKT3T1X9",
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const firestore = getFirestore(app);
export const auth = getAuth(app);
