import { Form, Input, message } from "antd";
import { doc, setDoc } from "firebase/firestore";
import { nanoid } from "nanoid";
import { useRecoilState, useRecoilValue } from "recoil";
import { firestore } from "../linker/linker";
import { messageState } from "../recoil/messageState";
import { UserMessage } from "../typeDefs/typeDefs";
import { windowState } from "../recoil/windowState";
import landscape from "./../Home/BGSat.jpg";
import { Button } from "@chakra-ui/react";

export const Contact = () => {
  const [contactForm] = Form.useForm();
  const [isMessage, setIsMessage] = useRecoilState(messageState);
  const { height } = useRecoilValue(windowState);

  const handleContactForm = () => {
    contactForm
      .validateFields()
      .then((values) => {
        const newId = nanoid();
        const userMessage = {
          ...values,
          inPipedrive: false,
        };
        setDoc(doc(firestore, `contacts/${newId}`), userMessage)
          .then((res) => {
            message.success("Thanks, we will be in touch!");
            setIsMessage({ ...userMessage, isSent: true });
          })
          .catch((err) => {
            message.error(
              "Sorry, we failed to get your message. Please try later."
            );
            console.error(err);
          });
      })
      .catch((err) => {
        message.error(
          "Sorry, we had a problem receiving your message. Please try again later."
        );
        console.error(err);
      });
  };

  const handleChange = () => {
    const fields = contactForm.getFieldsValue();
    setIsMessage({ ...fields, isSent: false });
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "80vh",
        paddingTop: "150px",
        paddingBottom: "150px",
        width: "100vw",
        //height: `${height - 150}px`,
        // backgroundImage: `url(${landscape})`,
        // backgroundColor: "rgba(255,255,255,.8)",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          fontSize: "3rem",
          marginTop: "3rem",
          paddingTop: "2rem",
          paddingBottom: "2rem",
          opacity: "1",
          width: "90%",
          maxWidth: "600px",
          backgroundColor: "rgba(255,255,255,.8)",
          border: "1px solid rgb(240,240,240)",
          borderRadius: "1rem",
        }}
      >
        <div
          style={{
            fontSize: "1.5rem",
            paddingTop: "2rem",
            paddingBottom: "1rem",
            fontFamily: "Lato",
          }}
        >
          CONTACT JESSE
        </div>
        {isMessage?.isSent ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontSize: "1rem",
                paddingTop: "1rem",
                paddingBottom: "2rem",
                maxWidth: "300px",
                textAlign: "center",
              }}
            >
              We have your message and will be in touch. Thanks for reaching out
              to us.
            </div>
            <Button
              onClick={() =>
                setIsMessage(
                  (prev) => ({ ...prev, isSent: false } as UserMessage)
                )
              }
            >
              New Message
            </Button>
          </div>
        ) : (
          <Form
            form={contactForm}
            style={{ width: "80%" }}
            initialValues={isMessage ? isMessage : {}}
            onChange={() => handleChange()}
          >
            <Form.Item
              name="name"
              rules={[{ required: true, message: "Please provide your name." }]}
            >
              <Input placeholder="Name" />
            </Form.Item>
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please provide a contact phone number",
                },
              ]}
            >
              <Input placeholder="Phone" />
            </Form.Item>
            <Form.Item name="email">
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
              name="message"
              rules={[
                {
                  required: true,
                  message: "Please provide a reason for getting in touch.",
                },
              ]}
            >
              <Input.TextArea rows={7} placeholder="Message..." />
            </Form.Item>
            <Button onClick={() => handleContactForm()}>Send</Button>
          </Form>
        )}
      </div>
    </div>
  );
};
