import { AgentPage } from "../Agents/AgentPage";

export const Team = () => {
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <AgentPage />
    </div>
  );
};
