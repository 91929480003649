import { Image } from "antd";
import { useRecoilValue } from "recoil";
import { windowState } from "../recoil/windowState";
import poster from "./profile.jpg";
import { Center } from "@chakra-ui/react";

export const AgentPage = () => {
  const { width } = useRecoilValue(windowState);
  return (
    <div
      style={{
        width: "100vw",
        paddingTop: "100px",
        paddingBottom: "100px",
        //backgroundColor: "rgba(255,255,255,.8)",
      }}
    >
      <Center width={"100%"}>
        <div
          style={{
            boxShadow: "5px 5px 20px #242424",
            width: width < 670 ? "100%" : "70%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#242424",
          }}
        >
          {width < 100 ? ( //670
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Image
                    preview={false}
                    src={poster}
                    style={{ maxHeight: "500px" }}
                  />
                </div>
                <div
                  style={{
                    fontFamily: "Lato",
                    padding: "1rem",
                  }}
                >
                  <div style={{ color: "#FFF359", fontSize: "2rem" }}>
                    JESSE HERSCHELL
                  </div>
                  <div style={{ fontSize: "1.5rem", color: "whitesmoke" }}>
                    Licensed Agent
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      fontSize: "1rem",
                      paddingTop: "1rem",
                      paddingBottom: "1rem",
                      color: "whitesmoke",
                    }}
                  >
                    {/* <div>
                      <u>P</u> 03 313 8829
                    </div> */}
                    <div>
                      <u>M</u> 021 1537 642
                    </div>
                    <div>
                      <u>E</u> jesse@herschell.nz
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: ".8rem",
                      padding: "1rem",
                      backgroundColor: "whitesmoke",
                      width: "100%",
                    }}
                  >
                    Jesse comes with a vast amount of experience in sales across
                    various industries. His strong communication skills allow
                    him to connect with sellers and buyers alike. Having worked
                    in property development and being a multi-time vendor
                    himself, he understands that it's not just about taking the
                    listing, it's about seeing the bigger picture. It's about
                    working hard for his clients all the way through, and
                    getting the job done. Jesse's entrepreneurial personality
                    helps him not only to get things moving but to remain
                    focused, driven, and open-minded. Jesse is proud to be an
                    active agent.
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: width < 1000 ? "column" : "row",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  width: "100%",
                  height: "100%",
                  fontFamily: "Playfair",
                  backgroundColor: "white",
                }}
              >
                <Image
                  preview={false}
                  src={poster}
                  style={{
                    width: "100%",
                    height: "100%",
                    //minWidth: "300px",
                    maxHeight: "500px",
                  }}
                />
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      color: "rgb(15,30,66)",
                      height: "50%",
                      fontFamily: "Lato",
                      padding: "1.5rem",
                      width: width < 1300 ? "fit-content" : "100%",
                    }}
                  >
                    <div
                      style={{
                        fontSize: width < 1300 ? "2rem" : "4rem",
                        fontOpticalSizing: "auto",
                        letterSpacing: ".6rem",
                      }}
                    >
                      Jesse Herschell
                    </div>
                    <div
                      style={{
                        fontSize: width < 1300 ? "1.5rem" : "2rem",
                        color: "rgb(15,30,66)",
                        letterSpacing: ".3rem",
                      }}
                    >
                      Licensed Sales Consultant
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      width: width < 1300 ? "fit-content" : "100%",
                      flexDirection: "row",
                      // justifyContent: "space-between",
                      fontSize: width < 1300 ? ".8rem" : "1.25rem",
                      padding: "1.5rem",
                      color: "rgb(15,30,66)",
                      fontFamily: "Lato",
                    }}
                  >
                    {/* <div style={{ paddingRight: "2rem" }}>
                      <u>P</u> 03 313 8829
                    </div> */}
                    <div style={{ paddingRight: "2rem" }}>
                      <u>M</u> 021 1537 642
                    </div>
                    <div>
                      <u>E</u> jesse@herschell.nz
                    </div>
                  </div>
                </div>
              </div>{" "}
              <div
                style={{
                  fontSize: "1rem",

                  height: "50%",
                  backgroundColor: "white",
                }}
              >
                <div style={{ padding: "3rem" }}>
                  Jesse comes with a vast amount of experience in sales across
                  various industries. His strong communication skills allow him
                  to connect with sellers and buyers alike. Having worked in
                  property development and being a multi-time vendor himself, he
                  understands that it's not just about taking the listing, it's
                  about seeing the bigger picture. It's about working hard for
                  his clients all the way through, and getting the job done.
                  Jesse's entrepreneurial personality helps him not only to get
                  things moving but to remain focused, driven, and open-minded.
                  Jesse is proud to be an active agent.
                </div>
              </div>
            </>
          )}
        </div>
      </Center>
    </div>
  );
};
