import React, { Suspense, lazy } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./Home/Home";
import { Header } from "./Header/Header";
import { Footer } from "./Footer/Footer";
import { ConfigProvider, Spin } from "antd";
import { RecoilRoot } from "recoil";
import { Listings } from "./Listings/Listings";
import { Report } from "./Report/Report";
import { Contact } from "./Contact/Contact";
import { Special } from "./Special/Special";
import { SecureRoute } from "./SecureRoute/SecureRoute";
import { LoadingOutlined } from "@ant-design/icons";
import { AuthProvider } from "./AuthProvider/AuthProvider";
import { Team } from "./Team/Team";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { Buy } from "./Buy/Buy";
import Video from "./Home/background.mp4";

const DASHBOARD = lazy(() => import("./Dashboard/Dashboard"));
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
function App() {
  const theme = extendTheme({
    colors: {
      brand: {
        100: "#242424",
        200: "#fee017",
        300: "#E6F9FF",
        400: "#78DCFD",
        500: "#05C0FF",
        600: "#131313",
        700: "#FAFBFB",
      },
    },
  });

  return (
    <ChakraProvider theme={theme}>
      <ConfigProvider theme={{ token: { colorPrimary: "#000000" } }}>
        <RecoilRoot>
          <BrowserRouter>
            <AuthProvider>
              <div
                style={{ height: "100vh", width: "100vw", overflow: "scroll" }}
              >
                <div
                  style={{
                    zIndex: 15,
                    position: "fixed",
                    top: "0px",
                    left: "0px",
                  }}
                >
                  <Header />
                </div>
                <div
                  style={{
                    zIndex: 10,
                    position: "fixed",
                    top: "0px",
                    left: "0px",
                  }}
                >
                  <video
                    id="bannerVideo"
                    autoPlay
                    muted
                    loop
                    controls={false}
                    style={{
                      position: "relative",
                      width: "100vw",
                      height: "100vh",
                      overflow: "hidden",
                      objectFit: "cover",
                    }}
                  >
                    <source
                      src={Video}
                      type="video/mp4"
                      style={{
                        position: "relative",
                        //width: "100vw",
                        height: "100vh",
                        overflow: "hidden",
                      }}
                    />
                  </video>
                </div>
                <div
                  style={{
                    overflowY: "scroll",
                    height: "100vh",
                    zIndex: 11,
                    position: "fixed",
                    top: "0px",
                    left: "0px",
                  }}
                >
                  <Routes>
                    <Route path="/" element={<Home />} />

                    <Route path="/the-herschell-team" element={<Team />} />
                    {/* <Route
                      path="/calculator"
                      element={<Listings page="home" />}
                    /> */}
                    {/* <Route path="/buy" element={<Listings page="buy" />} /> */}
                    {/* <Route path="/herschell-report" element={<Report />} /> */}
                    <Route path="/contact" element={<Contact />} />
                    {/* <Route path="/new-zealand-owned" element={<Special />} /> */}
                    {/* <Route path="/buy/:listingId" element={<Buy />} /> */}
                    <Route
                      path="/dashboard"
                      element={
                        <SecureRoute>
                          <Suspense fallback={<Spin indicator={antIcon} />}>
                            <DASHBOARD />
                          </Suspense>
                        </SecureRoute>
                      }
                    />
                  </Routes>
                  {/* <Footer /> */}
                </div>
              </div>
            </AuthProvider>
          </BrowserRouter>
        </RecoilRoot>
      </ConfigProvider>
    </ChakraProvider>
  );
}

export default App;
